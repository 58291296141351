<template>
  <v-container class="grey lighten-5">
    <v-row>
      <v-col col-12 class="main-col-margin">
        <v-card class="blank-card project-form">
          <v-card-title class="main-card-title-offset">
            <v-icon large left color="white">
              mdi-folder-multiple-outline
            </v-icon>
            <div class="no-padding-left">
              {{
                $t('project.edit.title', {
                  refnum: currentProjectData.reference_number
                })
              }}
            </div>
          </v-card-title>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.status.title') }}
              <v-btn
                class="button-right"
                icon
                @click="showStatus = !showStatus"
              >
                <v-icon>{{
                  showStatus ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showStatus">
                <v-select
                  v-model="payload.status"
                  :items="projectConstants.statuses"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('project.edit.status.label')"
                ></v-select>

                <v-text-field
                  v-model="payload.projectName"
                  :label="$t('project.edit.status.name')"
                  required
                ></v-text-field>

                <v-textarea
                  :label="$t('project.edit.status.description')"
                  v-model="payload.projectDescription"
                >
                </v-textarea>

                <v-select
                  v-model="payload.projectCountry"
                  :items="countries"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('project.edit.status.country')"
                  item-value="id"
                  item-text="nicename"
                ></v-select>

                <v-select
                  v-if="isUserAdmin"
                  style="margin-top: 10px"
                  v-model="payload.vendor"
                  :items="vendors"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('project.edit.status.vendor.label')"
                  :hint="$t('project.edit.status.vendor.hint')"
                  persistent-hint
                  item-value="id"
                  item-text="name"
                ></v-select>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.sectorAndApplication.title') }}
              <v-btn
                class="button-right"
                icon
                @click="showSectorApplication = !showSectorApplication"
              >
                <v-icon>{{
                  showSectorApplication ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showSectorApplication">
                <v-select
                  v-model="payload.sector"
                  :items="sectors"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('project.edit.sectorAndApplication.sector.label')"
                  item-value="id"
                  item-text="internal_name"
                  @change="setSectorApplicationsForCurrentSector(true)"
                ></v-select>
                <v-select
                  v-model="payload.sectorApplication"
                  :items="currentSectorApplications"
                  :menu-props="{ maxHeight: '400' }"
                  :label="
                    $t(
                      'project.edit.sectorAndApplication.sectorApplication.label'
                    )
                  "
                  item-value="id"
                  item-text="internal_name"
                ></v-select>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.conditions.title') }}
              <v-btn
                class="button-right"
                icon
                @click="showProjectConditions = !showProjectConditions"
              >
                <v-icon>{{
                  showProjectConditions ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showProjectConditions">
                <v-radio-group
                  :label="$t('project.edit.conditions.waterType.group')"
                  v-model="payload.projectConditions.waterType"
                  row
                >
                  <v-radio
                    v-for="waterType in projectConstants.water_types"
                    :key="'waterType_' + waterType"
                    name="waterType"
                    :label="
                      $t(`project.edit.conditions.waterType.label.${waterType}`)
                    "
                    :value="waterType"
                  ></v-radio>
                </v-radio-group>

                <v-text-field
                  class="project-inline-flex-form"
                  v-model="payload.projectConditions.waveHeight"
                  :label="$t('project.edit.conditions.waveHeight')"
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                >
                    <v-icon
                      slot="append"
                    >
                      m
                    </v-icon>
                </v-text-field>

                <v-text-field
                  class="project-inline-flex-form"
                  v-model="payload.projectConditions.waterDepthMin"
                  :label="$t('project.edit.conditions.waterDepthMin')"
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                >                
                  <v-icon
                    slot="append"
                  >
                    m
                  </v-icon>
                </v-text-field>

                <v-text-field
                  class="project-inline-flex-form"
                  v-model="payload.projectConditions.waterDepthMax"
                  :label="$t('project.edit.conditions.waterDepthMax')"
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                >                
                  <v-icon
                    slot="append"
                  >
                    m
                  </v-icon>
                </v-text-field>

                <v-text-field
                  class="project-inline-flex-form"
                  v-model="payload.projectConditions.tidalRange"
                  :label="$t('project.edit.conditions.tidalRange')"
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                >
                  <v-icon
                    slot="append"
                  >
                    m
                  </v-icon>
                </v-text-field>

                <v-text-field
                  class="project-inline-flex-form"
                  v-model="payload.projectConditions.waterFlowRate"
                  :label="$t('project.edit.conditions.waterFlowRange')"
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                >
                  <v-icon
                    slot="append"
                  >
                    m
                  </v-icon>
                </v-text-field>


                <v-text-field
                  class="project-inline-flex-form"
                  v-model="payload.projectConditions.windSpeed"
                  :label="$t('project.edit.conditions.windSpeed')"
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                >
                  <v-icon
                    slot="append"
                  >
                    m/s
                  </v-icon>
                </v-text-field>


                <v-radio-group
                  :label="$t('project.edit.conditions.anchorRequired.group')"
                  v-model="
                    payload.projectConditions.underwaterAnchoringRequired
                  "
                  row
                >
                  <v-radio
                    name="underwaterAnchoringRequired"
                    :label="
                      $t('project.edit.conditions.anchorRequired.label.yes')
                    "
                    :value="1"
                  ></v-radio>
                  <v-radio
                    name="underwaterAnchoringRequired"
                    :label="
                      $t('project.edit.conditions.anchorRequired.label.no')
                    "
                    :value="0"
                  ></v-radio>
                </v-radio-group>

                <v-radio-group
                  :label="
                    $t('project.edit.conditions.underwaterSubstrate.group')
                  "
                  v-model="payload.projectConditions.underwaterSubstrate"
                  row
                >
                  <v-radio
                    v-for="underwaterSubstrate in projectConstants.underwater_types"
                    :key="'underwaterSubstrate_' + underwaterSubstrate"
                    name="underwaterSubstrate"
                    :label="
                      $t(
                        `project.edit.conditions.underwaterSubstrate.label.${underwaterSubstrate}`
                      )
                    "
                    :value="underwaterSubstrate"
                  ></v-radio>
                  <v-radio
                    name="waterType"
                    :label="
                      $t(
                        'project.edit.conditions.underwaterSubstrate.label.custom.label'
                      )
                    "
                    value=" "
                  ></v-radio>

                  <v-text-field
                    class="project-custom-input-field"
                    v-if="canCustomFieldBeDisplayed('underwaterSubstrate')"
                    v-model.trim="payload.projectConditions.underwaterSubstrate"
                    :label="
                      $t(
                        'project.edit.conditions.underwaterSubstrate.label.custom.description'
                      )
                    "
                    required
                  ></v-text-field>
                </v-radio-group>

                <v-radio-group
                  :label="$t('project.edit.conditions.shoreAnchor.group')"
                  v-model="payload.projectConditions.shoreAnchoringRequired"
                  row
                >
                  <v-radio
                    name="shoreAnchoringRequired"
                    :label="$t('project.edit.conditions.shoreAnchor.label.yes')"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    name="shoreAnchoringRequired"
                    :label="$t('project.edit.conditions.shoreAnchor.label.no')"
                    :value="0"
                  ></v-radio>
                </v-radio-group>

                <v-radio-group
                  :label="$t('project.edit.conditions.shoreSubstrate.group')"
                  v-model="payload.projectConditions.shoreSubstrate"
                  row
                >
                  <v-radio
                    v-for="shoreSubstrate in projectConstants.shore_types"
                    :key="'shoreSubstrate' + shoreSubstrate"
                    name="shoreSubstrate"
                    :label="
                      $t(
                        `project.edit.conditions.shoreSubstrate.label.${shoreSubstrate}`
                      )
                    "
                    :value="shoreSubstrate"
                  ></v-radio>
                  <v-radio
                    name="waterType"
                    :label="
                      $t(
                        'project.edit.conditions.shoreSubstrate.label.custom.label'
                      )
                    "
                    value=" "
                  ></v-radio>

                  <v-text-field
                    class="project-custom-input-field"
                    v-if="canCustomFieldBeDisplayed('shoreSubstrate')"
                    v-model.trim="payload.projectConditions.shoreSubstrate"
                    :label="
                      $t(
                        'project.edit.conditions.shoreSubstrate.label.custom.description'
                      )
                    "
                    required
                  ></v-text-field>
                </v-radio-group>

                <v-radio-group
                  :label="$t('project.edit.conditions.load.group')"
                  v-model="payload.projectConditions.loadMax"
                  row
                >
                  <v-radio
                    name="load"
                    :label="$t('project.edit.conditions.load.label.under350')"
                    :value="350"
                  ></v-radio>
                  <v-radio
                    name="load"
                    :label="
                      $t('project.edit.conditions.load.label.other.label')
                    "
                    value=" "
                  ></v-radio>
                </v-radio-group>

                <v-text-field
                  v-if="canCustomFieldBeDisplayed('maxLoad')"
                  class="project-inline-flex-form"
                  v-model.number="payload.projectConditions.loadMax"
                  :label="
                    $t('project.edit.conditions.load.label.other.description')
                  "
                  required
                  type="number"
                  min="0"
                  max="20"
                  step="0.1"
                ></v-text-field>

                <v-radio-group
                  :label="$t('project.edit.conditions.gateway.group')"
                  v-model="payload.projectConditions.gateway.required"
                  row
                >
                  <v-radio
                    name="gatewayRequired"
                    :label="$t('project.edit.conditions.gateway.label.yes')"
                    :value="1"
                  ></v-radio>
                  <v-radio
                    name="gatewayRequired"
                    :label="$t('project.edit.conditions.gateway.label.no')"
                    :value="0"
                  ></v-radio>
                </v-radio-group>

                <v-text-field
                  v-model="payload.projectConditions.gateway.length"
                  :label="$t('project.edit.conditions.gateway.label.length')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.projectConditions.gateway.width"
                  :label="$t('project.edit.conditions.gateway.label.width')"
                  required
                ></v-text-field>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.customer.title') }}
              <v-btn
                class="button-right"
                icon
                @click="showCustomerData = !showCustomerData"
              >
                <v-icon>{{
                  showCustomerData ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showCustomerData">
                <v-radio-group
                  :label="$t('project.edit.customer.type.group')"
                  v-model="payload.customer.type"
                  row
                >
                  <v-radio
                    name="customerType"
                    :label="$t('project.edit.customer.type.label.private')"
                    :value="customerTypes[0]"
                  ></v-radio>
                  <v-radio
                    name="customerType"
                    :label="$t('project.edit.customer.type.label.comercial')"
                    :value="customerTypes[1]"
                  ></v-radio>
                </v-radio-group>

                <v-select
                  v-model="payload.customer.country"
                  :items="countries"
                  :menu-props="{ maxHeight: '400' }"
                  :label="$t('project.edit.customer.country')"
                  item-value="id"
                  item-text="nicename"
                ></v-select>

                <v-text-field
                  v-model="payload.customer.company"
                  :label="$t('project.edit.customer.company')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.street"
                  :label="$t('project.edit.customer.street')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.postalCode"
                  :label="$t('project.edit.customer.postalCode')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.place"
                  :label="$t('project.edit.customer.place')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.name"
                  :label="$t('project.edit.customer.name')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.role"
                  :label="$t('project.edit.customer.role')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.phone"
                  :label="$t('project.edit.customer.phone')"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="payload.customer.email"
                  :label="$t('project.edit.customer.email')"
                  required
                ></v-text-field>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.files.title') }}
              <v-btn class="button-right" icon @click="showFiles = !showFiles">
                <v-icon>{{
                  showFiles ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showFiles">
                <v-file-input
                  :label="$t('project.edit.files.input.label')"
                  v-model="file"
                  @change="uploadFile()"
                ></v-file-input>

                <v-data-table
                  :headers="fileHeader"
                  :items="allFiles"
                  :sort-by="[]"
                  :sort-desc="[false, true]"
                  multi-sort
                  class="elevation-1"
                >
                  <template v-slot:[`item.controls`]="props">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="error"
                      @click="deleteFile(props.item, props.index)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>

                    <!-- Currently uploaded files are not yet moved to files so they cant be dowhnloaded -->
                    <v-btn
                      v-if="props.item.download_path"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="
                        downloadFile(props.item.name, props.item.original_name)
                      "
                    >
                      <v-icon dark>mdi-cloud-download</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.productList.title') }}
              <v-btn
                class="button-right"
                icon
                @click="showProductList = !showProductList"
              >
                <v-icon>{{
                  showProductList ? 'mdi-chevron-up' : 'mdi-chevron-down'
                }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <div v-show="showProductList">
                <v-data-table
                  :headers="productListHeaders"
                  :items="productList"
                  :sort-by="[]"
                  :sort-desc="[false, true]"
                  multi-sort
                  class="elevation-1"
                >
                  <template v-slot:[`item.image`]="props">
                    <v-img
                      :src="props.item.editor_image_path"
                      class="product-variation-datatable-image"
                    ></v-img>
                  </template>
                </v-data-table>
              </div>
            </v-expand-transition>
          </v-card>

          <v-card class="mx-auto form-card-padding">
            <v-card-title
              class="title font-weight-light v-card-title-no-padding-left"
            >
              {{ $t('project.edit.actions.title') }}
            </v-card-title>

            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="updateProject()"
              >{{ $t('project.edit.actions.controlls.update') }}</v-btn
            >
            <v-btn
              color="#b30a00"
              class="ma-2 white--text"
              @click="
                getPDF()
                pdf.overlay.loading = !pdf.overlay.loading
              "
            >
              {{ $t('project.edit.actions.controlls.getPdf') }}
              <v-icon right dark>
                mdi-file-pdf
              </v-icon>
            </v-btn>

            <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="sendEmail()"
            >
              {{ $t('project.edit.actions.controlls.sendEmail') }}
              <v-icon right dark>
               mdi-send
              </v-icon>
            </v-btn>
            <!--
            <v-btn
              color="success"
              class="ma-2 white--text"
              @click="getExcell()"
            >
              {{ $t('project.edit.actions.controlls.getExcell') }}
              <v-icon right dark>
                mdi-file-excel
              </v-icon>
            </v-btn>
            -->
          </v-card>
        </v-card>
      </v-col>
    </v-row>
    <error-handler :error="error"></error-handler>
    <v-dialog v-model="pdf.overlay.loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ $t('project.edit.getPdf.processing.description') }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="pdf.overlay.download"
      hide-overlay
      persistent
      width="300"
    >
      <v-card class="mx-auto" max-width="344">
        <v-card-text>
          <p class="display-1 text--primary text-center">
            {{ $t('project.edit.getPdf.ready.title') }}
          </p>
          <div class="text--primary text-center">
            {{ $t('project.edit.getPdf.ready.description') }}
          </div>
        </v-card-text>
        <v-card-actions>
          <a :href="pdf.overlay.link" target="_blank" download>
            <v-btn
              @click="pdf.overlay.download = !pdf.overlay.download"
              class="accent-4 white--text"
              color="teal"
            >
              {{ $t('project.edit.getPdf.ready.controlls.download') }}
            </v-btn>
          </a>
          <v-btn
            @click="pdf.overlay.download = !pdf.overlay.download"
            class="button-right white--text"
            color="#b30a00"
          >
            {{ $t('project.edit.getPdf.ready.controlls.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'EditProject',

  data () {
    return {
      error: null,
      currentProjectData: {},
      currentSectorApplications: [],
      showStatus: false,
      showSectorApplication: false,
      showProjectConditions: false,
      showCustomerData: false,
      showFiles: false,
      showProductList: false,
      file: null,
      currentProjectFiles: [],
      pdf: {
        overlay: {
          loading: false,
          download: false,
          link: ''
        }
      },
      fileHeader: [
        {
          text: this.$t('project.edit.files.table.label.file'),
          align: 'start',
          value: 'original_name'
        },
        {
          text: this.$t('project.edit.files.table.label.actions'),
          value: 'controls',
          sortable: false,
          align: 'center'
        }
      ],
      productListHeaders: [
        {
          text: this.$t('project.edit.productList.table.label.sku'),
          align: 'start',
          value: 'sku'
        },
        {
          text: this.$t('project.edit.productList.table.label.name'),
          value: 'product.name'
        },
        {
          text: this.$t('project.edit.productList.table.label.description'),
          align: 'start',
          value: 'product.description'
        },
        {
          text: this.$t('project.edit.productList.table.label.image'),
          value: 'image',
          sortable: false,
          align: 'center'
        },
        {
          text: this.$t('project.edit.productList.table.label.count'),
          value: 'count',
          sortable: false,
          align: 'center'
        }
      ],
      validation: {
        waterType: [v => !!v || 'Water Type is required'],
        lastnameRules: [
          v => !!v || 'Lastname is required',
          v =>
            (v && v.length <= 10) || 'Lastname must be less than 15 characters'
        ],
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ],
        passwordRules: [
          v => !!v || 'Password is required',
          v => (v && v.length > 5) || 'Passwrod must be more than 5 characther'
        ]
      },
      payload: {
        sector: null,
        sectorApplication: null,
        projectName: '',
        projectDescription: '',
        projectCountry: null,
        //similarProjectImage: [],
        vendor: null,
        //products: null,
        //configuration: null,
        //levelsPictures: null
        projectConditions: {
          waterType: '',
          waveHeight: null,
          waterDepthMin: null,
          waterDepthMax: null,
          tidalRange: null,
          waterFlowRate: null,
          windSpeed: null,
          underwaterAnchoringRequired: false,
          underwaterSubstrate: '',
          shoreAnchoringRequired: false,
          shoreSubstrate: '',
          gateway: {
            required: false,
            length: null,
            width: null
          },
          loadMax: null
        },
        customer: {
          type: '',
          country: null,
          company: '',
          street: '',
          postalCode: '',
          place: '',
          name: '',
          role: '',
          phone: '',
          email: ''
        },
        attachments: []
      }
    }
  },
  computed: {
    isUserAdmin () {
      return this.$store.getters.getUserData.admin
    },
    applicationData () {
      return this.$store.state.applicationData
    },
    sectors () {
      if (this.applicationData) {
        this.setSectorApplicationsForCurrentSector()
        return this.applicationData.sectors
      }
      return []
    },
    vendors () {
      if (this.applicationData) {
        return this.applicationData.vendors
      }
      return []
    },
    countries () {
      if (this.applicationData) {
        return this.applicationData.countries
      }
      return []
    },
    projectConstants () {
      if (this.applicationData) {
        return this.applicationData.project.constants
      }
      return {}
    },
    customerTypes () {
      if (this.applicationData) {
        return this.applicationData.project.constants.customer_types
      }
      return []
    },
    productList () {
      if (this.currentProjectData.productList) {
        return Object.values(this.currentProjectData.productList);
      }
      return []
    },
    allFiles () {
      return this.currentProjectFiles.concat(this.payload.attachments)
    }
  },
  mounted () {
    this.$store.dispatch('loadApplicationData')
    this.getProject()
  },
  methods: {
    getProject () {
      this.$axios({
        method: 'get',
        url: this.$globals.api.project.get + this.$route.params.id
      })
        .then(response => {
          //full dataset with relationship aditional data
          this.currentProjectData = response.data.data

          //basic data required for update operation
          this.payload.status = this.currentProjectData.status
          this.payload.sector = this.currentProjectData.sector.id
          this.payload.sectorApplication = this.currentProjectData.sector_application.id
          this.payload.projectName = this.currentProjectData.name
          this.payload.projectDescription = this.currentProjectData.description
          this.payload.projectCountry = this.currentProjectData.country.id
          this.payload.vendor = this.currentProjectData.vendor.id

          //this.payload.products = this.currentProjectData.products
          //this.payload.configuration = this.currentProjectData.configuration
          //this.payload.levelsPictures = this.currentProjectData.pdf_images

          this.payload.projectConditions.waterType = this.currentProjectData.conditions.water_type
          this.payload.projectConditions.waveHeight = this.currentProjectData.conditions.wave_height
          this.payload.projectConditions.waterDepthMin = this.currentProjectData.conditions.water_dept_min
          this.payload.projectConditions.waterDepthMax = this.currentProjectData.conditions.water_dept_max
          this.payload.projectConditions.tidalRange = this.currentProjectData.conditions.tidal_range
          this.payload.projectConditions.waterFlowRate = this.currentProjectData.conditions.water_flow_rate
          this.payload.projectConditions.windSpeed = this.currentProjectData.conditions.wind_speed
          this.payload.projectConditions.underwaterAnchoringRequired = this.currentProjectData.conditions.underwater_anchoring_required
          this.payload.projectConditions.underwaterSubstrate = this.currentProjectData.conditions.underwater_substrate
          this.payload.projectConditions.shoreAnchoringRequired = this.currentProjectData.conditions.shore_anchoring_required
          this.payload.projectConditions.shoreSubstrate = this.currentProjectData.conditions.shore_substrate
          this.payload.projectConditions.loadMax = this.currentProjectData.conditions.load

          this.payload.projectConditions.gateway.required = this.currentProjectData.conditions.gateway
          this.payload.projectConditions.gateway.length = this.currentProjectData.conditions.gateway_length
          this.payload.projectConditions.gateway.width = this.currentProjectData.conditions.gateway_width

          this.payload.customer.type = this.currentProjectData.customer.type
          this.payload.customer.country = this.currentProjectData.customer.country
          this.payload.customer.company = this.currentProjectData.customer.company
          this.payload.customer.street = this.currentProjectData.customer.street
          this.payload.customer.postalCode = this.currentProjectData.customer.postal_code
          this.payload.customer.place = this.currentProjectData.customer.place
          this.payload.customer.name = this.currentProjectData.customer.name
          this.payload.customer.role = this.currentProjectData.customer.role
          this.payload.customer.phone = this.currentProjectData.customer.phone
          this.payload.customer.email = this.currentProjectData.customer.email

          //we dont send all the files to the server just the newly uploaded ones
          this.currentProjectFiles = this.currentProjectData.files
        })
        .catch(error => {
          this.error = error.response
        })
    },
    setSectorApplicationsForCurrentSector (change = false) {
      //when initial call is made dont reset the payload value
      if (change) this.payload.sectorApplication = null

      if (this.applicationData) {
        this.applicationData.sectors.forEach(sector => {
          if (sector.id == this.payload.sector) {
            this.currentSectorApplications = sector.applications
          }
        })
      } else {
        this.currentSectorApplications = []
      }
    },
    canCustomFieldBeDisplayed (type) {
      if (this.applicationData) {
        switch (type) {
          case 'underwaterSubstrate':
            if (
              this.projectConstants.underwater_types.includes(
                this.payload.projectConditions.underwaterSubstrate
              )
            ) {
              return false
            }
            break
          case 'shoreSubstrate':
            if (
              this.projectConstants.shore_types.includes(
                this.payload.projectConditions.shoreSubstrate
              )
            ) {
              return false
            }
            break
          case 'maxLoad':
            if (this.payload.projectConditions.loadMax == 350) {
              return false
            }
            break
        }
      }
      return true
    },
    uploadFile () {
      //dont fire if user removes file from input
      if (this.file) {
        let formData = new FormData()
        formData.append('file', this.file)
        formData.append('type', 'attachment') //tell the server this is not a level image

        this.$axios({
          method: 'post',
          url: this.$globals.api.general.app.uploadFile,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            this.payload.attachments.push(response.data.data)
            this.file = null
          })
          .catch(error => {
            this.error = error.response
          })
      }
    },
    deleteFile (file) {
      //already uploaded files have ID and can be removed by POST call 
      if(file.id) {
        //AXIOS call to server to remove this file
        this.$axios({
          method: 'post',
          url: this.$globals.api.project.deleteProjectFile + this.$route.params.id + "/" + file.id,
        })
          .then(response => {
            console.log(response)
            this.currentProjectFiles = response.data.data
          })
          .catch(error => {
            this.error = error.response
          })
      } else {
        //newly uploaded files are just removed fromr array 
        this.payload.attachments.forEach((element, index) => {
          if(element.name == file.name){
            //remove the file from array 
            this.payload.attachments.splice(index, 1)
          }
        });
      }
      
    },
    downloadFile (name, origianlName) {
      this.$axios({
        method: 'post',
        url:
          this.$globals.api.general.app.downloadProjectFile +
          this.currentProjectData.id,
        data: { name: name },
        responseType: 'blob' // important
      })
        .then(response => {
          let extention = name.split('.').pop()
          let originalNameNoExtention = origianlName.replace(/\.[^.$]+$/, '')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${this.currentProjectData.name.replace(/\s+/g, '')}-${
              this.currentProjectData.reference_number
            }-${originalNameNoExtention}.${extention}`
          )
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => {
          this.error = error.response
        })
    },
    updateProject () {
      this.$axios({
        method: 'post',
        url: this.$globals.api.project.update + this.$route.params.id,
        data: this.payload
      })
        .then(() => {
          this.$router.push({ name: 'project/list' })
        })
        .catch(error => {
          this.error = error.response
        })
    },
    getPDF () {
      this.$axios({
        method: 'get',
        url:
          this.$globals.api.project.pdf.create +
          this.$route.params.id +
          '/' +
          this.$store.getters.getUserData.id
        //data: this.payload,
      })
        .then(response => {
          this.pdf.overlay.loading = false
          this.pdf.overlay.download = true

          this.pdf.overlay.link = response.data.data.url
          //window.open(response.data.data.url)
        })
        .catch(error => {
          this.error = error.response
        })
    },
    getExcell () {},
    sendEmail () {
      this.$axios({
        method: 'post',
        url: this.$globals.api.project.email.send + 
            this.$route.params.id + '/' +
            this.$store.getters.getUserData.id
      })
        .then(() => {
         
        })
        .catch(error => {
          this.error = error.response
        })
    }
  }
}
</script>
